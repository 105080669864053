import $ from 'jquery'

export default class ApplicationForm {
  constructor() {
    this.amount = 0
    this.sumOfReturnsAmount = 0
    this.supportTotalAmount = 0
  }

  start() {
    this.addTotalAmountEventListener()
    this.calculateAmount()
  }

  addTotalAmountEventListener() {
    $(document).on('ready turbolinks:load', () => {
      $('.js-returnCheckBox, .js-numOfReturns, .js-additionalAmount').on('change', () => {
        this.calculateAmount()
        this.setSumOfReturnAmounts()
        this.setSupportTotalAmount()
      })
    })
  }

  calculateAmount() {
    const $amounts = $('.js-return').map(function () {
      if (!$(this).find('.js-returnCheckBox').prop('checked')) {
        return
      }

      const numOfReturns = $(this).find('.js-numOfReturns').val()
      const amount = $(this).find('.js-returnAmount').attr('data-amount')

      return parseInt(numOfReturns) * parseInt(amount)
    })
    const amounts = $.makeArray($amounts)
    // 通常のサポートだけの合計を計算
    this.sumOfReturnsAmount = amounts.reduce((accumulator, current) => {
      return accumulator + current
    }, 0)

    // 上乗せ支援分も加算
    const additionalAmount = $('.js-additionalAmount').val()
    this.supportTotalAmount = parseInt(additionalAmount) + this.sumOfReturnsAmount
  }

  setSumOfReturnAmounts() {
    $('.js-sumOfReturnAmounts').text(this.sumOfReturnsAmount.toLocaleString())
  }

  setSupportTotalAmount() {
    $('.js-supportTotalAmount').text(this.supportTotalAmount.toLocaleString())
  }
}
