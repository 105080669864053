import $ from 'jquery'

export default class Tab {
  start() {
    this.addTabBarEventListener()
  }

  addTabBarEventListener() {
    $(document).on('ready turbolinks:load', function () {
      $('.js-tabBar__item').on('click', function () {
        $('.js-tab').removeClass('is-active')

        const tabName = $(this).attr('data-content')
        if (tabName) {
          $(`.js-tab--${tabName}`).addClass('is-active')
        }
      })
    })
  }
}
