import $ from 'jquery'

export default class Accordion {
  start() {
    this.addAccordionEventListener()
  }

  addAccordionEventListener() {
    $(document).on('ready turbolinks:load', function () {
      $('.js-accordion__label').on('click', function () {
        $(this).toggleClass('is-active')
        $(this).parents('.js-accordion').find('.js-accordion__content').slideToggle(400)
      })
    })
  }
}
