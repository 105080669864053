import $ from 'jquery'

export default class Accordion {
  start() {
    this.addReturnAccordion()
    this.addReturnAccordionEventListener()
  }

  addReturnAccordion() {
    $(document).on('ready turbolinks:load', function () {
      $('.js-returnAccordion__content').each(function() {
        let originalHeight = $(this).css({height: 'auto'}).height()
        if (originalHeight < 150) {
          $(this).parents('.js-returnAccordion').find('.js-returnAccordion__label').remove()
        } else {
          $(this).css({height: '100px'})
        }
      })
    })
  }

  addReturnAccordionEventListener() {
    $(document).on('ready turbolinks:load', function () {
      const minHeight = 100
      const duration = 300;
      $('.js-returnAccordion__label').on('click', function () {
        let showText = $(this).parents('.js-returnAccordion').find('.js-returnAccordion__content')
        let originalHeight = showText.css({height: 'auto'}).height()

        if($(this).hasClass('is-active')) {
          showText.animate({height: minHeight}, duration);
          $(this).text('もっと見る')
          $(this).removeClass('is-active')
        } else {
          showText.height(minHeight).animate({height: originalHeight}, duration);
          $(this).text('閉じる')
          $(this).addClass('is-active')
        }
      })
    })
  }
}
