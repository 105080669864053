import $ from 'jquery'

export default class Alert {
  start() {
    this.closeAutomatically()
  }

  // 特定のアラートを5秒後に閉じる
  closeAutomatically() {
    setTimeout(function () {
      $('.js-alert--closeAutomatically').alert('close')
    }, 5000)
  }
}
