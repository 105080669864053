import $ from 'jquery'

export default class TurbolinksOptimizer {
  start() {
    this.setupShareButtons()
  }

  setupShareButtons() {
    $(document).on('turbolinks:load', function () {
      // Facebook
      if(window.FB) {
        FB.XFBML.parse();
      }
      // LINE
      if (window.LineIt) {
        LineIt.loadButton()
      }
    });
  }
}
